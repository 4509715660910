import React from "react"
import { Link } from "gatsby"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"

import { TextElementNova } from "../../../../../../nutrafol-ui-kit/src/TextElementNova"

import AdChoicesLink from "../../../global/adchoiceslink/adchoiceslink"
import Logo from "../../../../../assets/icons/wild/nutrafol-logo-circle.svg"

import { data } from "../data"
import AdChoicesWrap from "../../../global/adchoiceslink/adchoiceswrap"

import LegalDisclaimer from "../../legal-disclaimer"

import { FooterContainer, MainBox, LogoWrap, StyledLinks } from "./index.styled"

const text =
  "© " +
  new Date().getFullYear() +
  "  Nutraceutical Wellness Inc. All Rights Reserved."

const LinkList = ({ list }) => {
  const handleCtaClick = (el) => {
    segmentEvent("Marketing CTA Clicked", {
      destination: el.link.split("?")[0],
      // module_location: "women_footer",
      text: el.title,
      type: "link",
    })
  }

  const linkList = list.map((el, idx) => {
    if (el.link === "adchoices") {
      const cls = process.env.GATSBY_RENDER_EVIDON === "1" ? "block" : "hidden"
      return (
        <div className={`link ${cls}`} key={`${el.link}-${idx}`}>
          <AdChoicesLink />
        </div>
      )
    }
    return (
      <div
        className={`link ${el.title.toLowerCase()}`}
        key={`${el.link}-${idx}`}
      >
        {el.link.indexOf("http") === 0 ? (
          <a
            href={el.link}
            onClick={() => handleCtaClick(el)}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`${el.title} (Opens in new tab)`}
          >
            <TextElementNova
              element="span"
              className="Body-10-Dia-Normal"
              text={el.title}
            />
          </a>
        ) : (
          <Link
            to={el.link}
            onClick={() => handleCtaClick(el)}
            state={el?.state ? el.state : {}}
          >
            <TextElementNova
              element="span"
              className="Body-10-Dia-Normal"
              text={el.title}
            />
          </Link>
        )}
      </div>
    )
  })
  return linkList
}

const NovaFooterLP = (props) => {
  const { referenceText } = props
  //NT2-20413 Site Redesign | Audit footer references addition
  return (
    <>
      {referenceText ? (
        <LegalDisclaimer data={{ content: referenceText }} />
      ) : null}
      <FooterContainer data-mousedown>
        <MainBox>
          <div className="left">
            <LogoWrap>
              <Logo />
            </LogoWrap>
          </div>
          <div className="middle">
            <TextElementNova
              element="p"
              text="These statements have not been approved by the Food and Drug Administration. This product is not intended to diagnose, treat, cure or prevent any disease."
              className="Body-10-Dia-Normal text"
            />

            <TextElementNova
              element="p"
              text={text}
              className="Body-10-Dia-Normal title"
            />
          </div>

          <div className="right">
            <StyledLinks>
              <TextElementNova
                element="h3"
                className="Caption-10-Dia-Uppercase section-header"
                text="Legal"
              />

              <LinkList list={data.policy} />
            </StyledLinks>
          </div>
        </MainBox>
      </FooterContainer>

      <AdChoicesWrap />
    </>
  )
}

export default NovaFooterLP
